import React from "react";
import { Form } from "react-bootstrap";
import {} from "./styles";
import { Controller } from "react-hook-form";

function InputHook(props) {
  const { name, type = "text", control, rules, placeholder, label } = props;

  return (
    <Form.Group className="custom-form-group mt-10">
      <Form.Label>{label}</Form.Label>
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field }) => {
          return (
            <Form.Control
              {...field}
              className="custom-input"
              type={type}
              placeholder={placeholder}
            />
          );
        }}
      />
    </Form.Group>
  );
}

export default InputHook;
