export const onlyUnsignedNumbers = (_string) =>
  _string?.replace(/[^0-9]+/g, "");

export const isValidCpf = (cpf_string) => {
  cpf_string = onlyUnsignedNumbers(cpf_string);
  if (!cpf_string) return false;

  // Elimina cpf_strings invalidos conhecidos
  if (
    cpf_string?.length !== 11 ||
    cpf_string === "00000000000" ||
    cpf_string === "11111111111" ||
    cpf_string === "22222222222" ||
    cpf_string === "33333333333" ||
    cpf_string === "44444444444" ||
    cpf_string === "55555555555" ||
    cpf_string === "66666666666" ||
    cpf_string === "77777777777" ||
    cpf_string === "88888888888" ||
    cpf_string === "99999999999"
  ) {
    return false;
  }
  // Valida 1o digito
  let add = 0;
  for (let i = 0; i < 9; i++) {
    add += parseInt(cpf_string.charAt(i)) * (10 - i);
  }
  let rev = 11 - (add % 11);
  if (rev === 10 || rev === 11) {
    rev = 0;
  }
  if (rev !== parseInt(cpf_string.charAt(9))) {
    return false;
  }

  // Valida 2o digito
  add = 0;
  for (let i = 0; i < 10; i++) {
    add += parseInt(cpf_string.charAt(i)) * (11 - i);
  }
  rev = 11 - (add % 11);
  if (rev === 10 || rev === 11) {
    rev = 0;
  }
  if (rev !== parseInt(cpf_string.charAt(10))) {
    return false;
  }

  return true;
};

export const validateCep = (cep) => {
  if (!cep) {
    return false;
  }
  // Remove any non-numeric characters
  const cleaned = cep.replace(/[^\d]/g, "");

  // Check if the cleaned number has exactly 11 digits
  return cleaned.length === 8;
};

export function converterParaFormatoISO(dataString) {
  const partes = dataString.split("/");
  const dia = partes[0];
  const mes = partes[1];
  const ano = partes[2];

  return `${ano}-${mes}-${dia}`;
}