import styled from 'styled-components';

export const Container = styled.div`

`;

export const ContainerTermos = styled.div`

`;

export const ContainerBotaoFinalizar = styled.div`
    text-align: center;
`;

export const BotaoFinalizar = styled.button`
    background: #F5A623;
    border: none;
    color: #fff;
    margin-left: auto;
    margin-right: auto;
    cursor: pointer;
    display: inline-block;
    font-weight: normal;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    user-select: none;
    border: 1px solid transparent;
    padding: 0.5rem 0.75rem;
    font-size: 1rem;
    line-height: 1.25;
    border-radius: 0.25rem;
    transition: all 0.15s ease-in-out;
`;

export const TextoTermos = styled.p`
    color: #000;
    font-size: 0.8rem;
`;