import styled from 'styled-components';

export const RulesContainer = styled.div`

`;

export const RulesTitle = styled.p`
    color: #F5A623;
    font-size: 14px;
    font-weight: bold;
    padding-left: 2rem;
`;

export const RulesList = styled.ul`
    list-style: inside;
    color: #777777;
    margin-left: 0;
    padding-left: 2rem;
`;