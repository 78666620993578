import React from 'react';
import { Form } from 'react-bootstrap';
import { Container, ContainerTermos, ContainerBotaoFinalizar, BotaoFinalizar, TextoTermos } from './styles';

function ContainerFinalizar({ setAceitoTermos }) {
    return (
        <Container>
            <ContainerTermos>
                <Form.Group controlId="CheckBoxTermos">
                    <Form.Check>
                        <Form.Check.Input type="checkbox" isValid  onChange={(e) => setAceitoTermos(e.target.checked)} />
                        <Form.Check.Label><TextoTermos>Ao finalizar, você concorda com nossos <a href="/termos-de-uso.php" target="_blank">Termos de uso</a> e <a href="/politica-de-privacidade.php" target="_blank">Política de privacidade</a></TextoTermos></Form.Check.Label>
                    </Form.Check>
                    
                </Form.Group>
            </ContainerTermos>
            <ContainerBotaoFinalizar>
                <BotaoFinalizar type="submit">
                    Finalizar
                </BotaoFinalizar>
            </ContainerBotaoFinalizar>
        </Container>
    )
}

export default ContainerFinalizar;