import React from 'react';
import { BannerContainer, BannerImage } from './styles';

function Banner({picture}){
    return (
        <BannerContainer>
            <BannerImage src={picture}></BannerImage>
        </BannerContainer>
    )
}

export default Banner;