import styled from 'styled-components';

export const HeaderContainer = styled.div``;

export const BodyContainer = styled.div``;

export const FormHeader = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const FormTitle = styled.p`
    font-size: 25px;
    margin-right: 10px;
    color: #F5A623;
    font-weight: bold;
    margin-bottom: 0;
`;

export const FormDivisorContainer = styled.div`
    display: flex;
    width: 100%;
    padding: 0.5rem
`;

export const FormDivisorLine = styled.div`
    width: 100%;
    border-bottom: solid 1px #F5A623
`;

export const FormDivisorText = styled.span`
    color: #F5A623;
    font-weight: bold;
    margin: auto;

    ${
        props => props.fontSize ? `font-size: 20px`:''
    }
`;

export const FormContainer = styled.div`
    margin-top: 3rem;
`;

export const FooterContainer = styled.div`
    margin-bottom: 50px;
`;

export const RulesContainer = styled.div``;