import { apiCEP, apiProdV2 } from "./api";

export const consultaCEP = async (value) => {
  const response = await apiCEP
    .get("/ws/" + value + "/json", {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then(({ ...response }) => {
      return response;
    })
    .catch(({ ...response }) => {
      return response;
    });
  return response;
};
