export const cities = [
  {
    value: 1,
    label: "CONCEIÇÃO DO ARAGUAIA",
    stores: [{ value: 3, label: "CONCEIÇÃO DO ARAGUAIA" }],
  },
  {
    value: 2,
    label: "IMPERATRIZ",
    stores: [{ value: 15, label: "MAIS MODAS IMPERATRIZ" }],
  },
  {
    value: 3,
    label: "MARABÁ",
    stores: [
      { value: 8, label: "CIDADE NOVA (AV SAO FRANCISCO)" },
      { value: 7, label: "CIDADE NOVA (AV NAGIB MUTRAN)" },
      { value: 6, label: "INDEPENDÊNCIA" },
      { value: 5, label: "NOVA MARABÁ" },
      { value: 4, label: "VELHA MARABÁ" },
    ],
  },
  { value: 4, label: "REDENÇÃO", stores: [{ value: 1, label: "MAIS MODAS" }] },
  {
    value: 5,
    label: "TAILANDIA",
    stores: [{ value: 10, label: "MAIS MODAS TAILANDIA" }],
  },
  {
    value: 6,
    label: "TUCUMÃ",
    stores: [{ value: 9, label: "MAIS MODAS TUCUMÃ" }],
  },
  {
    value: 7,
    label: "XINGUARA",
    stores: [{ value: 2, label: "MAIS MODAS XINGUARA" }],
  },
];

export const sexoOptions = [
  { value: 0, label: "Masculino" },
  { value: 1, label: "Feminino" },
  { value: 2, label: "Não informar" },
];
