import React from "react";
import { RulesContainer, RulesTitle, RulesList } from "./styles";

function Rules({ rulesTitle, rulesList }) {
  return (
    <RulesContainer>
      <RulesTitle>{rulesTitle}</RulesTitle>
      <RulesList>
        {rulesList.map((item) => (
          <li>{item}</li>
        ))}
      </RulesList>
    </RulesContainer>
  );
}

export default Rules;
