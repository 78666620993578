import React from "react";
import { Form } from "react-bootstrap";
import {} from "./styles";
import { Controller } from "react-hook-form";
import InputMask from "react-input-mask";

function InputMaskHook(props) {
  const {
    name,
    type = "text",
    control,
    placeholder,
    rules,
    mask,
    label,
  } = props;

  return (
    <Form.Group className="custom-form-group mt-10">
      <Form.Label>{label}</Form.Label>
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field }) => {
          return (
            <InputMask
              {...field}
              mask={mask}
              onBlur={props.onBlur}
              onChange={(event) => {
                field.onChange(event.target.value);
                if (props.onChange) {
                  props.onChange(event.target.value);
                }
              }}
            >
              {(inputProps) => (
                <Form.Control
                  {...inputProps}
                  className="custom-input"
                  placeholder={placeholder}
                  type={type}
                />
              )}
            </InputMask>
          );
        }}
      />
    </Form.Group>
  );
}

export default InputMaskHook;
