import Campanha from "./pages/campanha";
import './css/global.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {BrowserRouter as Router, Route,  Switch } from "react-router-dom";

function App() {
  return (
    <Router>
      <Switch>
        <Route path='/' component={Campanha} />
      </Switch>
    </Router>
  );
}

export default App;
