import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { Col, Row } from "react-bootstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import LoadingOverlay from "react-loading-overlay";

import Banner from "../../components/banner";
import {
  BodyContainer,
  HeaderContainer,
  FormContainer,
  FormHeader,
  FormTitle,
  FormDivisorContainer,
  FormDivisorLine,
  FormDivisorText,
  FooterContainer,
  RulesContainer,
} from "./styles";
import Rules from "../../components/Rules";
import { ErrorToast } from "../../util/alert";
import ContainerFinalizar from "../../components/ContainerFinalizar";
import axios from "axios";
import { cities, sexoOptions } from "../../mock";
import Image from "../../assets/images/logo-mais-modas.png";
import Image2 from "../../assets/images/logo-mais-modas-2.png";
import { useForm } from "react-hook-form";
import InputHook from "../../components/InputHookForm";
import InputMaskHook from "../../components/InputMaskHookForm";
import { converterParaFormatoISO, validateCep } from "../../util/functions";
import { consultaCEP } from "../../services/localizacao";
import { apiEx } from "../../services/api";
import SelectHookForm from "../../components/SelectHookForm";

// const BASE_URL = "http://localhost:3335";
const BASE_URL = "https://api-mais-modas.portalctech.com.br";

const formatarData = (data) => {
  if (!data) return "";
  const [ano, mes, dia] = data.split("-");
  return `${dia}/${mes}/${ano}`;
};

function Campanha() {
  const { control, reset, handleSubmit, trigger, setError, watch, getValues } =
    useForm({
      shouldFocusError: false,
    });

  const cidadeSelecionada = watch("cidade");

  const [isLoading, setIsLoading] = useState(false);
  const [textoIsLoading, setTextoIsLoading] = useState(
    "Estamos finalizando seu cadastro"
  );
  const [lojaSelecionada, setLojaSelecionada] = useState(false);
  const [lojasDisponiveis, setLojasDisponiveis] = useState([]);

  const [aceitoTermos, setAceitoTermos] = useState(false);
  const [mostrarAlertaJaCadastrado, setMostrarAlertaJaCadastrado] =
    useState(false);
  const [mostrarAlertaFinalizado, setMostrarAlertaFinalizado] = useState(false);
  const [mostrarAlertaErro, setMostrarAlertaErro] = useState(false);
  const [mostrarAlertaLojaBloqueada, setMostrarAlertaLojaBloqueada] =
    useState(false);
  const [estadosDisponiveis, setEstadosDisponiveis] = useState([]);
  const [cidadesDisponiveisEndereco, setCidadesDisponiveisEndereco] = useState(
    []
  );

  const rulesList = [
    "Realizar o cadastro / atualização dos dados preenchendo o formulário acima;",
    "Compras acima de R$ 80,00 vc irá ganhar um cartão presente no valor de R$ 30,00;",
    "O crédito é intransferível. Só poderá ser retirado pela titular do cadastro;",
    "Não é acumulativo;",
    "Cadastro permitido apenas para maiores de 18 anos;",
    "Válido cadastrar, somente 1 membro por família;",
    "Para a validação da promoção, será necessário a apresentação de um documento com foto e o documento do exame de teste de gravidez positivo;",
    "Validação da promoção será feita pela gerente da loja;",
    "Válido em qualquer loja física da rede Mais Modas;",
  ];
  /**VALORES DO FORMULÁRIO */

  function getLojas(event) {
    const { stores } = event;

    setLojasDisponiveis(stores);
  }

  function changeLoja(event) {
    let idLoja = event?.value;
    if (idLoja === "") {
      setLojaSelecionada(false);
      return;
    }
    if (!idLoja) {
      return;
    }

    setLojaSelecionada(true);
  }

  function validarIdade(data) {
    data = data.split("/");
    const mes = parseInt(data[1]);
    const dia = parseInt(data[0]);
    const ano = parseInt(data[2]);
    const maiorIdade = new Date(ano + 18, mes - 1, dia) <= new Date();
    return maiorIdade;
  }

  function validarData(data) {
    try {
      data = data.split("/");
      let dia = parseInt(data[0]);
      let mes = parseInt(data[1]);
      let ano = parseInt(data[2]);

      if (mes > 12 || mes < 1) {
        return false;
      }

      if (
        (mes === 4 || mes === 6 || mes === 9 || mes === 11) &&
        (dia > 30 || dia < 0)
      ) {
        return false;
      }

      if (
        (mes === 1 ||
          mes === 2 ||
          mes === 5 ||
          mes === 7 ||
          mes === 8 ||
          mes === 10 ||
          mes === 12) &&
        (dia > 31 || dia < 0)
      ) {
        return false;
      }

      if (mes === 2 && (dia > 28 || dia < 0)) {
        return false;
      }

      if (ano < 1900) {
        return false;
      }

      return true;
    } catch (error) {
      return false;
    }
  }

  function verificarCpf(e) {
    let cpf = e.target.value.replace(/[^\d]+/g, "");
    if (cpf.length !== 11) {
      ErrorToast({
        message: "O campo CPF parece não estar completo",
        headerMessage: "Ops, há pendencias de preenchimento:",
      });
      return;
    }
    setTextoIsLoading("Estamos verificando se você já está cadastrado");
    setIsLoading(true);

    axios
      .get(`${BASE_URL}/maismodas/customers/check-cpf-campanha`, {
        params: {
          cpf: e.target.value,
          campaignId: "compre_ganhe",
        },
      })
      .then((data) => {
        setIsLoading(false);
        setTextoIsLoading("Estamos finalizando seu cadastro");
        console.log({ data: data.data });
        if (data.data?.codCliente) {
          console.log("heeere");
          console.log({
            ...data.data,
          });

          console.log();

          if (data.data?.uf) {
            cidadesPorEstado({ value: data.data?.uf });
          }

          reset({
            ...getValues(),
            cpf: data.data?.cpf,
            sexo: data.data?.sexo,
            nomeCliente: data.data?.nomeCliente,
            fone1: data.data?.fone1,
            email: data.data?.email,
            dataNascimento: formatarData(data.data?.dataNascimento),
            numeroEndereco: data.data?.numeroEndereco,
            endereco: data.data?.endereco,
            bairro: data.data?.bairro,
            cidadeEndereco: data.data?.cidadeEndereco
              ? cidadesDisponiveisEndereco?.find(
                  (item) =>
                    item.value === data.data.cidadeEndereco.toUpperCase()
                )
              : undefined,
            estado: estadosDisponiveis?.find(
              (item) => item.value === data.data?.uf
            ),
            cep: data.data?.cep,
            codIBGE: data.data?.codIBGE,
          });
        }
      })
      .catch((error) => {
        console.log({ error });

        if (
          error?.response?.status === 400 &&
          error?.response?.data?.alreadyRegistered
        ) {
          setMostrarAlertaJaCadastrado(true);
        }
        setIsLoading(false);
        setTextoIsLoading("Estamos finalizando seu cadastro");
      });
  }

  const findCep = async (value) => {
    console.log({ value });

    const cep = value.replace(/[^\d]+/g, "");
    if (!validateCep(cep)) {
      trigger("cep");
      return;
    }
    trigger("cep");

    if (!cep) {
      return null;
    }

    const response = await consultaCEP(cep);
    console.log({ response });

    if (response?.status === 200) {
      if (response?.data?.uf) {
        cidadesPorEstado({ value: response?.data?.uf });
      }
      console.log(
        estadosDisponiveis?.find((item) => item.value === response?.data?.uf)
      );
      console.log({ estadosDisponiveis });

      reset({
        ...getValues(),
        estado: estadosDisponiveis?.find(
          (item) => item.value === response?.data?.uf
        ),
        cidadeEndereco: response?.data?.localidade
          ? cidadesDisponiveisEndereco?.find(
              (item) => item.value === response.data.localidade.toUpperCase()
            )
          : undefined,
        bairro: response?.data?.bairro,
        endereco: response?.data?.logradouro,
        codIBGE: response?.data?.ibge,
      });
    } else {
      setError("cep", { message: "CEP Inválido" });
    }
  };

  async function onSubmit(formData) {
    console.log(formData);

    if (!aceitoTermos) {
      return ErrorToast({
        message: "Aceite os termos de uso e política de privacidade",
        headerMessage: "Há pendências de preenchimento:",
      });
    }

    if (!validarData(formData.dataNascimento)) {
      ErrorToast({
        message: "",
        headerMessage: "Data inválida!",
      });
      return;
    }
    if (!validarIdade(formData.dataNascimento)) {
      ErrorToast({
        message: "Cadastro permitido somente para maiores de 18 anos",
        headerMessage: "Cadastro não permitido!",
      });
      return;
    }
    const data = {
      ...formData,
      loja: formData?.loja?.value,
      cidade: formData?.cidade?.label,
      cidadeEndereco: formData?.cidadeEndereco?.label,
      estado: formData?.estado?.sigla,
      sexo: formData?.sexo?.value,
      dataNascimento: converterParaFormatoISO(formData.dataNascimento),
      campaignId: "compre_ganhe",
    };
    console.log({ data });

    try {
      setIsLoading(true);
      await axios
        .post(`${BASE_URL}/maismodas/customers/register`, data)
        .then((data) => {
          console.log({ data });
          if (data.status === 200) {
            setMostrarAlertaFinalizado(true);
          }
        })
        .catch((error) => {
          setIsLoading(false);
          setTextoIsLoading("Estamos finalizando seu cadastro");
        });

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setMostrarAlertaErro(true);
    }
  }

  async function onError(errorData) {
    console.log({ errorData });

    const message = [];

    Object.keys(errorData).forEach((item) => {
      message.push(errorData[item].message || "");
    });
    ErrorToast({
      message: message.join("; "),
      headerMessage: "Há pendências de preenchimento:",
    });
  }

  const getEstados = async () => {
    try {
      const response = await apiEx.get("/estados").then((data) => {
        if (data?.data) {
          return data.data.map((item) => ({
            value: item.sigla,
            label: `${item.sigla} - ${item.nome}`,
            sigla: item.sigla,
          }));
        } else {
          return { error: true, data };
        }
      });
      if (response?.error) {
        throw new Error("Erro");
      }
      setEstadosDisponiveis(response);
    } catch (error) {
      setEstadosDisponiveis([]);
      setCidadesDisponiveisEndereco([]);
    }
  };

  const cidadesPorEstado = async (event) => {
    console.log({ event });

    const id = event.value;
    try {
      const response = await apiEx
        .get("/estados/" + id + "/municipios")
        .then((data) => {
          if (data?.data) {
            return data.data.map((item) => {
              return { value: item.nome.toUpperCase(), label: item.nome };
            });
          } else {
            return { error: true, data: data };
          }
        })
        .catch((err) => {
          return { error: true, err: err };
        });

      if (response?.error) {
        throw new Error("Erro");
      }
      console.log({ response });

      setCidadesDisponiveisEndereco(response);
    } catch (error) {
      setCidadesDisponiveisEndereco([]);
    }
  };

  useEffect(() => {
    getEstados();
  }, []);

  return (
    <>
      <HeaderContainer>
        <Banner picture={Image} />
      </HeaderContainer>
      <BodyContainer>
        <form onSubmit={handleSubmit(onSubmit, onError)}>
          <FormContainer>
            <Col xs={12} md={6} className="m-auto">
              <FormHeader>
                <FontAwesomeIcon color="#F5A623" icon={faUser} size={"2x"} />
                <FormTitle>Cadastre-se</FormTitle>
              </FormHeader>
              <FormDivisorContainer>
                <Col sm={3} className="d-none d-sm-flex">
                  <FormDivisorLine></FormDivisorLine>
                </Col>
                <Col sm={6} className="d-none d-sm-flex">
                  <FormDivisorText>
                    preencha o formulário abaixo
                  </FormDivisorText>
                </Col>
                <Col sm={3} className="d-none d-sm-flex">
                  <FormDivisorLine></FormDivisorLine>
                </Col>
              </FormDivisorContainer>
            </Col>
            <Col xs={12} md={6} className="m-auto pt-3">
              <Row>
                <Col xs={12} md={6}>
                  <SelectHookForm
                    name="cidade"
                    rules={{ required: "Selecione uma cidade" }}
                    control={control}
                    options={cities}
                    onChange={getLojas}
                    placeholder="Selecione uma cidade"
                    label="Cidade*"
                  />
                </Col>

                <Col xs={12} md={6}>
                  <SelectHookForm
                    name="loja"
                    disabled={!cidadeSelecionada}
                    rules={{ required: "Selecione uma loja" }}
                    control={control}
                    options={lojasDisponiveis}
                    onChange={changeLoja}
                    placeholder="Selecione uma loja"
                    label="Loja*"
                  />
                </Col>
              </Row>

              {lojaSelecionada ? (
                <>
                  <Row>
                    <Col xs={12} md={6}>
                      <InputMaskHook
                        name="cpf"
                        control={control}
                        label="CPF*"
                        rules={{ required: "Informe o seu CPF" }}
                        placeholder="000.000.000-00"
                        mask="999.999.999-99"
                        inputMode="numeric"
                        onBlur={verificarCpf}
                      />
                    </Col>
                    <Col xs={12} md={6}>
                      <InputHook
                        name="nomeCliente"
                        control={control}
                        rules={{ required: "Informe o seu nome" }}
                        placeholder="Digite seu nome"
                        label="Nome Completo*"
                      />
                    </Col>

                    <Col xs={12} md={6}>
                      <InputMaskHook
                        name="fone1"
                        label="Telefone (WhatsApp)*"
                        rules={{ required: "Informe seu telefone" }}
                        placeholder="(00) 00000-0000"
                        mask="(99)99999-9999"
                        inputMode="numeric"
                        control={control}
                      />
                    </Col>

                    <Col xs={12} md={6}>
                      <InputHook
                        name="email"
                        label="Email*"
                        control={control}
                        placeholder="seuemail@email.com"
                        inputMode="email"
                      />
                    </Col>
                    <Col xs={12} md={6}>
                      <InputMaskHook
                        name="dataNascimento"
                        label="Data de Nascimento*"
                        rules={{ required: "Informe a data de nascimento" }}
                        mask="99/99/9999"
                        inputMode="numeric"
                        control={control}
                      />
                    </Col>

                    <Col xs={12} md={6}>
                      <SelectHookForm
                        name="sexo"
                        rules={{ required: "Selecione o sexo" }}
                        control={control}
                        options={sexoOptions}
                        placeholder="Selecione o sexo"
                        label="Sexo*"
                      />
                    </Col>
                  </Row>

                  <FormDivisorContainer>
                    <Col sm={3} className="d-none d-sm-flex">
                      <FormDivisorLine></FormDivisorLine>
                    </Col>
                    <Col sm={6} className="d-none d-sm-flex">
                      <FormDivisorText>Endereço</FormDivisorText>
                    </Col>
                    <Col sm={3} className="d-none d-sm-flex">
                      <FormDivisorLine></FormDivisorLine>
                    </Col>
                  </FormDivisorContainer>

                  <Row>
                    <Col xs={12} md={6}>
                      <InputMaskHook
                        name="cep"
                        label="CEP *"
                        rules={{ required: "Informe o CEP" }}
                        mask="99999-999"
                        inputMode="numeric"
                        control={control}
                        placeholder="CEP *"
                        onChange={(event) => {
                          findCep(event);
                        }}
                      />
                    </Col>
                    <Col xs={12} md={6}>
                      <SelectHookForm
                        name="estado"
                        rules={{ required: "Selecione o estado do endereço" }}
                        control={control}
                        options={estadosDisponiveis}
                        onChange={cidadesPorEstado}
                        placeholder="Selecione um estado"
                        label="Estado *"
                      />
                    </Col>

                    <Col xs={12} md={6}>
                      <SelectHookForm
                        name="cidadeEndereco"
                        rules={{ required: "Selecione a cidade do endereço" }}
                        control={control}
                        options={cidadesDisponiveisEndereco}
                        placeholder="Selecione uma cidade"
                        label="Cidade *"
                      />
                    </Col>

                    <Col xs={12} md={6}>
                      <InputHook
                        name="bairro"
                        label="Bairro*"
                        control={control}
                        rules={{ required: "Informe o bairro do endereço" }}
                      />
                    </Col>

                    <Col xs={12} md={6}>
                      <InputHook
                        name="endereco"
                        label="Endereço*"
                        control={control}
                        rules={{ required: "Informe seu endereço" }}
                      />
                    </Col>

                    <Col xs={12} md={6}>
                      <InputHook
                        name="numeroEndereco"
                        label="Número*"
                        rules={{ required: "Informe o número endereço" }}
                        control={control}
                      />
                    </Col>
                  </Row>

                  <ContainerFinalizar setAceitoTermos={setAceitoTermos} />
                </>
              ) : (
                ""
              )}
            </Col>
          </FormContainer>
        </form>
        <FooterContainer className="small">
          <Col xs={12} md={6} className="m-auto">
            <FormDivisorContainer>
              <Col sm={3} className="d-none d-sm-flex">
                <FormDivisorLine></FormDivisorLine>
              </Col>
              <Col sm={6} className="d-none d-sm-flex">
                <FormDivisorText fontSize={20}>@lojasmaismodas</FormDivisorText>
              </Col>
              <Col sm={3} className="d-none d-sm-flex">
                <FormDivisorLine></FormDivisorLine>
              </Col>
            </FormDivisorContainer>
            <RulesContainer>
              <Rules rulesTitle="*Regras" rulesList={rulesList} />
            </RulesContainer>
          </Col>
        </FooterContainer>

        <ToastContainer />

        {mostrarAlertaJaCadastrado ? (
          <SweetAlert
            confirmBtnCssClass="cor-aprincipal"
            title=""
            onConfirm={() =>
              window.location.replace(
                "https://www.instagram.com/lojasmaismodas/"
              )
            }
            allowEscape={false}
            closeOnClickOutside={false}
            confirmBtnText="Visite nosso insta"
          >
            <div className="mt-2 mt-sm-4 small">
              <div
                style={{
                  fontSize: 15,
                  fontWeight: "bold",
                  textAlign: "center",
                  marginBottom: 20,
                  marginTop: 20,
                  color: "#F5A623",
                }}
              >
                <img
                  src={Image2}
                  alt="Imagem promoção compre e ganhe"
                  style={{ maxWidth: "100%", maxHeight: 350, marginBottom: 15 }}
                />
                <h2>Você já está cadastrado nessa campanha</h2>
                <div>@lojasmaismodas</div>
                <div className="row">
                  <div
                    className="col-4 d-none d-md-block"
                    style={{ borderBottom: "solid 1px #F5A623" }}
                  ></div>
                  <div className="col-4 d-none d-md-block"></div>
                  <div
                    className="col-4 d-none d-md-block"
                    style={{ borderBottom: "solid 1px #F5A623" }}
                  ></div>
                </div>
              </div>
              <h4 className="mb-2" style={{ color: "#595959" }}>
                Se ainda não resgatou seus produtos, vá até nossas lojas
              </h4>
              <h4 style={{ color: "#F5A623" }} className="mb-2">
                Regras:
              </h4>
              <ul style={{ listStyle: "inside" }} className="text-left">
                <li>
                  Realizar o cadastro / atualização dos dados preenchendo o
                  formulário acima;
                </li>
                <li>
                  Compras acima de R$ 80,00 vc irá ganhar um cartão presente no
                  valor de R$ 30,00
                </li>
                <li>
                  O crédito é intransferível. Só poderá ser retirado pela
                  titular do cadastro;
                </li>
                <li>Não é acumulativo;</li>
                <li>Cadastro permitido apenas para maiores de 18 anos;</li>
                <li>Válido cadastrar, somente 1 membro por família;</li>
                <li>
                  Para a validação da promoção, será necessário a apresentação
                  de um documento com foto e o documento do exame de teste de
                  gravidez positivo;
                </li>
                <li>Validação da promoção será feita pela gerente da loja;</li>
                <li>Válido em qualquer loja física da rede Mais Modas;</li>
              </ul>
            </div>
          </SweetAlert>
        ) : (
          ""
        )}

        {mostrarAlertaFinalizado ? (
          <SweetAlert
            confirmBtnCssClass="cor-aprincipal"
            title=""
            onConfirm={() =>
              window.location.replace(
                "https://www.instagram.com/lojasmaismodas/"
              )
            }
            allowEscape={false}
            closeOnClickOutside={false}
            confirmBtnText="Visite nosso insta"
          >
            <div className="mt-2 mt-sm-4 small">
              <div
                style={{
                  fontSize: 15,
                  fontWeight: "bold",
                  textAlign: "center",
                  marginBottom: 20,
                  marginTop: 20,
                  color: "#F5A623",
                }}
              >
                <img
                  alt="Imagem promoção compre e ganhe"
                  src={Image2}
                  style={{ maxWidth: "100%", maxHeight: 350, marginBottom: 15 }}
                />
                <h2>
                  Você ganhou R$30,00 em produtos, em compras acima de R$ 80,00,
                  😃😃 passe em nossa loja e escolha seu presente.
                </h2>
                <div>@lojasmaismodas</div>
                <div className="row">
                  <div
                    className="col-4 d-none d-md-block"
                    style={{ borderBottom: "solid 1px #F5A623" }}
                  ></div>
                  <div className="col-4 d-none d-md-block"></div>
                  <div
                    className="col-4 d-none d-md-block"
                    style={{ borderBottom: "solid 1px #F5A623" }}
                  ></div>
                </div>
              </div>
              <h4 style={{ color: "#F5A623" }} className="mb-2">
                Regras:
              </h4>
              <ul style={{ listStyle: "inside" }} className="text-left">
                <li>
                  Realizar o cadastro / atualização dos dados preenchendo o
                  formulário acima;
                </li>
                <li>
                  Compras acima de R$ 80,00 vc irá ganhar um cartão presente no
                  valor de R$ 30,00
                </li>
                <li>
                  O crédito é intransferível. Só poderá ser retirado pela
                  titular do cadastro;
                </li>
                <li>Não é acumulativo;</li>
                <li>Cadastro permitido apenas para maiores de 18 anos;</li>
                <li>Válido cadastrar, somente 1 membro por família;</li>
                <li>
                  Para a validação da promoção, será necessário a apresentação
                  de um documento com foto e o documento do exame de teste de
                  gravidez positivo;
                </li>
                <li>Validação da promoção será feita pela gerente da loja;</li>
                <li>Válido em qualquer loja física da rede Mais Modas;</li>
              </ul>
            </div>
          </SweetAlert>
        ) : (
          ""
        )}

        {mostrarAlertaErro ? (
          <SweetAlert
            confirmBtnCssClass="cor-aprincipal"
            title="Ops, Ocorreu algum problema ao tentar cadastrar"
            onConfirm={() => setMostrarAlertaErro(false)}
            error
          >
            Não conseguimos finalizar o seu cadastro, tente novamente
          </SweetAlert>
        ) : (
          ""
        )}

        {mostrarAlertaLojaBloqueada ? (
          <SweetAlert
            confirmBtnCssClass="cor-aprincipal"
            title="Ops, loja não disponível"
            onConfirm={() => setMostrarAlertaLojaBloqueada(false)}
            error
          >
            Esta loja não está disponível no baby+ convênio no momento
          </SweetAlert>
        ) : (
          ""
        )}
        <div
          className={
            isLoading ? "showBackgroundLoading" : "hideBackgroundLoading"
          }
        >
          <LoadingOverlay
            active={isLoading}
            spinner
            text={textoIsLoading}
          ></LoadingOverlay>
        </div>
        {/* {estado && <Loading estado={estado} />} */}
      </BodyContainer>
    </>
  );
}

export default Campanha;
