import axios from "axios";

const api = axios.create({
  baseURL: "https://api.aprincipalbb.com.br",
});

export const apiCEP = axios.create({
  baseURL: "https://viacep.com.br/",
});

export const apiEx = axios.create({
  baseURL: "https://servicodados.ibge.gov.br/api/v1/localidades",
});

export default api;
