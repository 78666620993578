import React from "react";
import Select from "react-select";
import { Form } from "react-bootstrap";
import {} from "./styles";
import { Controller } from "react-hook-form";

function SelectHookForm(props) {
  const {
    name,
    options = [],
    control,
    rules,
    placeholder,
    label,
    disabled,
  } = props;

  return (
    <Form.Group className="custom-form-group mt-10">
      <Form.Label>{label}</Form.Label>
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field }) => {
          return (
            <Select
              {...field}
              options={options}
              onChange={(event) => {
                field.onChange(event);
                if (props.onChange) {
                  props.onChange(event);
                }
              }}
              placeholder={placeholder}
              isDisabled={disabled}
            />
          );
        }}
      />
    </Form.Group>
  );
}

export default SelectHookForm;
